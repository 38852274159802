import React from "react";
import { Link, PageProps } from "gatsby";
import DefaultLayout from "../layouts/DefaultLayout";

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="404: Page not found.">
    <div className="container">
      <p>
        The page you are looking for does not exist.{" "}
        <Link to="/">Go back.</Link>
      </p>
    </div>
  </DefaultLayout>
);

export default Page;
